<template>
  <div class="card">
    <div class="card-body">
      <div class="mb-5">
        <div
          v-if="!props.corporation"
          class="mb-3"
        >
          <label for="quotation-corporation" class="form-label">企業・団体名</label>
          <input
            id="quotation-corporation"
            class="form-control"
            type="text"
            v-model="state.corporation"
            placeholder="入力してください"
          >
        </div>
        <div class="mb-3">
          <label for="quotation-name" class="form-label">担当者氏名</label>
          <input
            id="quotation-name"
            class="form-control"
            type="text"
            v-model="state.name"
            placeholder="入力してください"
          >
        </div>
      </div>
      <a
        :href="downloadPath" target="_blank"
        class="btn btn-primary"
        :class="{ disabled: !canDownload }">
        御見積書をダウンロードする
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, reactive, computed } from 'vue';
import { PriceMap } from 'types/models';
import { globalCorporationLicenseQuotationPath } from '@/lib/rails-routes';

type Props = {
  visibleState: boolean
  priceMapList: PriceMap[]
  finalPrice: number
  corporation?: string
  name?: string
}

type State = {
  corporation: string
  name: string
}

const props = defineProps<Props>();
const state = reactive<State>({
  corporation: props.corporation ?? '',
  name: props.name ?? '',
});
const quantity = (month: number): string => {
  const found = props.priceMapList.find((priceMap) => priceMap.month === month);
  if (found) {
    return found.quantity.toString(10);
  }
  return '0';
};
const downloadPath = computed(() => {
  const params = {
    m1: quantity(1),
    m2: quantity(2),
    m3: quantity(3),
    m6: quantity(6),
    m12: quantity(12),
    m24: quantity(24),
    m36: quantity(36),
    v: props.visibleState ? '1' : '0',
    c: state.corporation,
    n: state.name,
  };
  return globalCorporationLicenseQuotationPath(params);
});
const canDownload = computed(
  () => props.finalPrice > 0
  && state.corporation.length > 0
  && state.name.length > 0,
);
</script>
