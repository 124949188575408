import { reactive } from 'vue';
import { DiscountParam, PriceMap } from '@/types/models';

type PriceTable = {
  month: number
  price: number
}[]

type State = {
  priceMapList: PriceMap[]
  discountTable: DiscountParam[]
  totalPrice: number
  totalCount: number
  discountRate: number
  discountPrice: number
  discountTotalPrice: number
  taxPrice: number
  finalPrice: number
  perPrice: number
}

type useCorporationLicenseCalculatorResult = {
  calculatorState: State
  changePrice: (price: number) => void
  updateTotal: () => void
  changeDiscountTable: (table: DiscountParam[]) => void
  changePriceMapList: (list: PriceMap[]) => void
}

export const useCorporationLicenseCalculator = (
  priceTable: PriceTable,
  discountTable: DiscountParam[],
  taxRate: number,
): useCorporationLicenseCalculatorResult => {
  const initialPriceMapList = () => priceTable.map(
    (obj) => ({
      month: obj.month,
      price: obj.price,
      quantity: 0,
      subTotal: 0,
    }),
  );

  const calculatorState = reactive<State>({
    priceMapList: initialPriceMapList(),
    discountTable,
    totalPrice: 0,
    totalCount: 0,
    discountRate: 0,
    discountPrice: 0,
    discountTotalPrice: 0,
    taxPrice: 0,
    finalPrice: 0,
    perPrice: 0,
  });

  const discountRate = () => calculatorState.discountTable
    .find((discount) => discount.price <= calculatorState.totalPrice)!.rate;

  const changePrice = (price: number) => {
    calculatorState.totalPrice = price;
    calculatorState.discountRate = discountRate();
    calculatorState.discountPrice = calculatorState
      .totalPrice * (calculatorState.discountRate / 100);
    calculatorState.discountTotalPrice = calculatorState.totalPrice - calculatorState.discountPrice;
    calculatorState.taxPrice = calculatorState.discountTotalPrice * (taxRate / 100);
    calculatorState.finalPrice = calculatorState.discountTotalPrice + calculatorState.taxPrice;
    calculatorState.perPrice = calculatorState
      .finalPrice > 0 ? Math.floor(calculatorState.finalPrice / calculatorState.totalCount) : 0;
  };

  const updateTotal = () => {
    const { totalPrice, totalCount } = calculatorState.priceMapList.reduce((prev, priceMap) => ({
      totalPrice: prev.totalPrice + priceMap.subTotal,
      totalCount: prev.totalCount + priceMap.quantity,
    }), { totalPrice: 0, totalCount: 0 });
    calculatorState.totalCount = totalCount;
    changePrice(totalPrice);
  };

  const changeDiscountTable = (table: DiscountParam[]) => {
    calculatorState.discountTable = table;
    changePrice(calculatorState.totalPrice);
  };

  const changePriceMapList = (list: PriceMap[]) => {
    calculatorState.priceMapList = list;
  };

  return {
    calculatorState,
    changePrice,
    updateTotal,
    changeDiscountTable,
    changePriceMapList,
  };
};
