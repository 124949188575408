<template>
  <div>
    <table class="table table-bordered text-right bg-white mb-3">
      <thead class="text-center">
      <th colspan="2">金額</th>
      </thead>
      <tbody>
      <tr>
        <th>小計</th>
        <td>{{ numberToYen(props.totalPrice) }}</td>
      </tr>
      <tr>
        <th>割引（{{ props.discountRate }}%）</th>
        <td v-if="props.discountPrice === 0">0円</td>
        <td v-else>-{{ numberToYen(props.discountPrice) }}</td>
      </tr>
      <tr>
        <th>小計（割引後）</th>
        <td>{{ numberToYen(props.discountTotalPrice) }}</td>
      </tr>
      <tr>
        <th>消費税</th>
        <td>{{ numberToYen(props.taxPrice) }}</td>
      </tr>
      <tr>
        <th>合計（税込）</th>
        <td>{{ numberToYen(props.finalPrice) }}</td>
      </tr>
      <tr v-if="props.perPrice">
        <th>
          1ライセンスあたり
          <br/>
          <small>(合計/ライセンス数)</small>
        </th>
        <td>{{ numberToYen(props.perPrice) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { numberToYen } from '@/lib/utils';

type Props = {
  totalPrice: number
  discountRate: number
  discountPrice: number
  discountTotalPrice: number
  taxPrice: number
  finalPrice: number
  perPrice?: number
}

const props = defineProps<Props>();
</script>
