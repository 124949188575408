<template>
  <table class="table table-bordered bg-white">
    <thead>
      <tr class="text-center">
        <th colspan="2">割引率</th>
      </tr>
      <slot name="under-header" />
    </thead>
    <tbody class="text-right">
      <tr
          v-for="discount in props.discountTable"
          :key="discount.rate"
          :class="{ 'table-warning': discount.rate === props.currentRate }"
      >
        <td>{{ discount.label }}</td>
        <td>{{ discount.rate }}%</td>
      </tr>
    </tbody>
  </table>
</template>
<script setup lang="ts">
import { defineProps } from 'vue';
import { DiscountParam } from '@/types/models';

type Props = {
  discountTable: DiscountParam[]
  currentRate: number
}

const props = defineProps<Props>();
</script>
