<template>
  <div class="row">
    <div class="col-xl-5 mb-4">
      <corporation-license-price-table
        class="bg-white"
        :price-map-list="calculatorState.priceMapList"
        :totalPrice="calculatorState.totalPrice"
        :totalCount="calculatorState.totalCount"
        :editable="true"
        @changeQuantity="handleChangeQuantity"
      />
    </div>
    <div class="col-xl-3 mb-4">
      <corporation-license-discount-table
        :discount-table="calculatorState.discountTable"
        :current-rate="calculatorState.discountRate"
      >
        <template v-slot:under-header>
          <tr>
            <td colspan="2">
              <div class="form-check">
                <input
                  id="visible"
                  type="radio"
                  class="form-check-input"
                  :value="true"
                  v-model="state.visibleState"
                  @change="handleChangeVisibleState"
                >
                <label for="visible">紹介許可をする</label>
              </div>
              <div class="form-check">
                <input
                  id="invisible"
                  type="radio"
                  class="form-check-input"
                  :value="false"
                  v-model="state.visibleState"
                  @change="handleChangeVisibleState"
                >
                <label for="invisible">紹介許可をしない</label>
              </div>
            </td>
          </tr>
        </template>
      </corporation-license-discount-table>
    </div>
    <div class="col-xl-4 mb-4">
      <corporation-license-total-amount
        class="mb-5"
        :totalPrice="calculatorState.totalPrice"
        :discountRate="calculatorState.discountRate"
        :discountPrice="calculatorState.discountPrice"
        :discountTotalPrice="calculatorState.discountTotalPrice"
        :taxPrice="calculatorState.taxPrice"
        :finalPrice="calculatorState.finalPrice"
        :perPrice="calculatorState.perPrice"
      />
      <corporation-license-quotation-download
        :visibleState="state.visibleState"
        :priceMapList="calculatorState.priceMapList"
        :finalPrice="calculatorState.finalPrice"
        :corporation="corporationName"
        :name="corporationStaffName"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineProps, reactive } from 'vue';
import CorporationLicensePriceTable from '@/components/CorporationLicensePriceTable.vue';
import CorporationLicenseDiscountTable from '@/components/CorporationLicenseDiscountTable.vue';
import CorporationLicenseTotalAmount from '@/components/CorporationLicenseTotalAmount.vue';
import CorporationLicenseQuotationDownload from '@/components/CorporationLicenseQuotationDownload.vue';
import { DiscountParam } from '@/types/models';
import { useCorporationLicenseCalculator } from '@/hooks/useCorporationLicenseCalculator';

type Props = {
  priceTable: { month: number, price: number }[]
  visibleDiscountTable: DiscountParam[]
  invisibleDiscountTable: DiscountParam[]
  taxRate: number
  corporationName?: string
  corporationStaffName?: string
}

type State = {
  visibleState: boolean
}

const props = defineProps<Props>();
const state = reactive<State>({
  visibleState: true,
});

const {
  calculatorState,
  updateTotal,
  changeDiscountTable,
} = useCorporationLicenseCalculator(props.priceTable, props.visibleDiscountTable, props.taxRate);

const handleChangeVisibleState = () => {
  const discountTable = state.visibleState
    ? props.visibleDiscountTable : props.invisibleDiscountTable;
  changeDiscountTable(discountTable);
};
const handleChangeQuantity = (index: number, quantity: number) => {
  const priceMap = calculatorState.priceMapList[index];
  priceMap.quantity = quantity;
  priceMap.subTotal = priceMap.price * Math.max(0, priceMap.quantity);
  updateTotal();
};

updateTotal();
</script>
