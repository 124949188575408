<template>
  <table class="table table-bordered text-right">
    <thead>
    <tr class="text-center">
      <th colspan="4">専用ライセンス価格表と選択済みライセンス</th>
    </tr>
    <tr>
      <th>期間</th>
      <th>価格</th>
      <th>数</th>
      <th>計</th>
    </tr>
    </thead>
    <tbody>
    <tr
      v-for="(priceMap, index) in priceMapList"
      :key="priceMap.month"
      class="text-nowrap"
    >
      <td v-text="monthToTerm(priceMap.month)" />
      <td v-text="numberToYen(priceMap.price)" />
      <td class="w-25">
        <div v-if="editable">
          <input
            class="form-control"
            type="number"
            min="0"
            v-model.number="state.quantities[index]"
            @change="handleChangedQuantity(index)"
          />
        </div>
        <div v-else>
          {{ priceMap.quantity }}
        </div>
      </td>
      <td v-text="numberToYen(priceMap.subTotal)" />
    </tr>
    <tr>
      <td colspan="2">合計</td>
      <td v-text="totalCount" />
      <td v-text="numberToYen(totalPrice)" />
    </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { defineProps, reactive, defineEmits } from 'vue';
import { PriceMap } from '@/components/CorporationLicenseOrder.vue';
import { monthToTerm, numberToYen } from '@/lib/utils';

type Props = {
  priceMapList: PriceMap[]
  editable: boolean
  totalPrice: number
  totalCount: number
}
type State = {
  quantities: number[]
}
type Emits = {
  (emit: 'changeQuantity', index: number, quantity: number): void
}

const props = defineProps<Props>();
const state = reactive<State>({
  quantities: [...props.priceMapList.map((priceMap) => priceMap.quantity)],
});
const emit = defineEmits<Emits>();

const handleChangedQuantity = (index: number) => {
  emit('changeQuantity', index, state.quantities[index]);
};
</script>
